<template>
  <BaseLayout>
    <template v-slot:header>
      <Menu />
    </template>

    <div>
      <page-title message-key="message.formsign"></page-title>
    </div>

    <div>
      <div class="p-4">
    <form @submit.prevent="submitForm" class="mb-6">
      <div class="mb-4">
        <input
          type="text"
          v-model="signataire.prenom"
          placeholder="First Name"
          class="input border border-gray-300 rounded p-2 w-full"
          required />
      </div>
      <div class="mb-4">
        <input
          type="text"
          v-model="signataire.nom"
          placeholder="Last Name"
          class="input border border-gray-300 rounded p-2 w-full"
          required />
      </div>
      <div class="mb-4">
        <input
          type="email"
          v-model="signataire.email"
          placeholder="Email"
          class="input border border-gray-300 rounded p-2 w-full"
          required />
      </div>
      <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Submit
      </button>
    </form>
    <h2 class="text-lg font-bold mb-3">Confirmed Signatories:</h2>
  
    <ul>
      <li
        v-for="user in confirmedUsers"
        :key="user.id"
        class="border-b border-gray-200 p-2">
        {{ user.prenom }} {{ user.nom }}
      </li>
    </ul>
  </div>

    </div>
  </BaseLayout>
</template>



<script>
import BaseLayout from '../layouts/BaseLayout.vue';

export default {
  components: {
    BaseLayout,
  },
  data() {
    return {
      signataire: {
        prenom: '',
        nom: '',
        email: '',
      },
      confirmedUsers: [],
    };
  },
  created() {
    this.fetchConfirmedUsers();
  },
  methods: {
    async submitForm() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.signataire)
    };

    try {
        const response = await fetch('http://127.0.0.1:8000/api/signataires/', requestOptions);
        const data = await response.json();

        if (!response.ok) {
            // Since the error message is in an array under the 'email' key, we need to check accordingly
            if (response.status === 400 && data.email && data.email[0].includes('signataire with this email already exists')) {
                alert('This email is already in use. Please use a different email.');
            } else {
                // If the error is not due to the email already existing, throw a generic error
                throw new Error('Failed to submit. Please try again later.');
            }
        } else {
            alert('Please check your email to confirm your submission.');
            this.fetchConfirmedUsers();  // Refresh the list of confirmed users
            this.signataire = { prenom: '', nom: '', email: '' }; // Reset form
        }
    } catch (error) {
        console.error('There was an error submitting the form:', error);
        alert(error.message);
    }
},
    async fetchConfirmedUsers() {
      const url = 'http://127.0.0.1:8000/api/signataires/?confirmed=true';
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        this.confirmedUsers = await response.json();
      } catch (error) {
        console.error('There was an error fetching the confirmed users:', error);
        console.log('Failed fetching from:', url);
      }
    }
  },
};
</script>