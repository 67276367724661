const messages = {
  en: {
    message: {
      call: 'Read the Call',
      title: 'Call to Register the Muqaddima by Ibn Khaldūn in the UNESCO\'s Memory of the World Record',
      ibnkhaldun: 'Ibn Khaldūn',
      almuqaddima: 'al-Muqaddima',
      manuscripts: 'Manuscripts',
      bibliography: 'Bibliography',
      sign: 'Sign the Call!',
      formsign: 'Please fill this form; you\'ll get an e-mail to confirm.',
      listmanuscripts: 'List of manuscripts',
    },
  },
  fr: {
    message: {
      call: 'Lire l\'Appel',
      title: 'Appel pour l\'inscription de la Muqaddima d\'Ibn Khaldūn sur le registre de la Mémoire du Monde',
      ibnkhaldun: 'Ibn Khaldūn',
      almuqaddima: 'al-Muqaddima',
      manuscripts: 'Manuscrits',
      bibliography: 'Bibliographie',
      sign: 'Signer l\'Appel !',
      formsign: 'Prière de remplir ce formulaire. Vous receverez un e-mail de confirmation.',
      listmanuscripts: 'Listes des manuscrits',
    },
  },
  ar: {
    message: {
      call: 'اقرأ النداء',
      title: 'نداء لتسجيل « مقدّمة » ابن خلدون على لائحة ذاكرة العالم لليونسكو',
      ibnkhaldun: 'ابن خلدون',
      almuqaddima: 'المقدّمة',
      manuscripts: 'مخطوطات',
      bibliography: 'بيبليوغرافيا',
      sign: 'إمضاء الدعوة !',
      formsign: 'الرجاء تعمير الاستمارة. سيصلكم بريد لتأكيد الإمضاء.',
      listmanuscripts: 'قائمة المخطوطات',
    },
  },
  es: {
    message: {
      title: 'Llamamiento para la inscripción del Muqaddima en el Registro de la Memoria del Mundo Ibn Jaldún y el Muqaddima',
      // Add other Arabic translations here
    },
  },
  de: {
    message: {
      title: 'Aufruf für die Eintragung der Muqaddima in das Register des Gedächtnisses der Menschheit Ibn Khaldūn (1332-1406) und die Muqaddima',
      // Add other Arabic translations here
    },
  },
};

export default messages;
