<template>
    <div>
      <h1>Email Confirmation</h1>
      <p v-if="message">{{ message }}</p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        message: '',
      };
    },
    methods: {
      async confirmEmail(token) {
        try {
          const response = await fetch(`http://127.0.0.1:8000/api/confirm/${token}`);
          if (response.ok) {
            this.message = 'Your email has been successfully confirmed!';
          } else {
            this.message = 'There was an error confirming your email. Please try again.';
          }
        } catch (error) {
          this.message = 'There was a network error confirming your email.';
        }
      },
    },
    mounted() {
      const token = this.$route.params.token;
      if (token) {
        this.confirmEmail(token);
      }
    },
  };
  </script>
  