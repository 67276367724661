<template>
  <div class="p-6">
    <div>
          <page-title message-key="message.bibliography"></page-title>
    </div>

    <div class="flex flex-wrap -mx-4">
      <div v-for="notice in notices" :key="notice.id" class="mb-8 px-4 w-1/3">
        <div class="flex flex-col rounded shadow-lg bg-white">
          <div class="p-4">
            <h2 class="text-xl font-semibold">{{ notice.titre }}</h2>
            <p v-if="notice.editeur && notice.editeur.editeur">Editor: {{ notice.editeur.editeur }}</p>
          </div>
          <img v-if="notice.thumbnail_url" :src="`https://back.al-muqaddima.net${notice.thumbnail_url}`" alt="Thumbnail" class="flex-grow object-cover">
          <div class="p-4">
            <div v-if="notice.responsabilites && notice.responsabilites.length">
              <h3 class="font-semibold">Responsibilities:</h3>
              <div v-for="(group, functionTitle) in groupByFunction(notice.responsabilites)" :key="functionTitle">
                <h4>{{ functionTitle }}</h4>
                <ul>
                  <li v-for="resp in group" :key="resp.id">
                    {{ resp.autorite.prenom }} {{ resp.autorite.nom }}
                  </li>
                </ul>
              </div>
            </div>
            <!-- Display other non-null fields -->
            <p v-if="notice.annee">Year: {{ notice.annee }}</p>
            <p v-if="notice.isbn">ISBN: {{ notice.isbn }}</p>
            <!-- Add other fields as needed -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import BaseLayout from '../layouts/BaseLayout.vue';

const notices = ref([]);

onMounted(async () => {
  const response = await fetch('https://back.al-muqaddima.net/biblio/api/notices/');
  if (response.ok) {
    let data = await response.json();
    notices.value = data.sort((a, b) => {
      if (a.edition_critique === b.edition_critique) {
        return b.traduction - a.traduction;
      }
      return b.edition_critique - a.edition_critique;
    });
  } else {
    alert('Error fetching data');
  }
});

function groupByFunction(responsabilites) {
  const grouped = {};
  for (const resp of responsabilites) {
    const functionName = resp.fonction.intitule;
    if (!grouped[functionName]) {
      grouped[functionName] = [];
    }
    grouped[functionName].push(resp);
  }
  return grouped;
}
</script>

<style scoped>
/* Ensure the image takes the remaining height */
.flex-grow {
  flex-grow: 1;
}
img.flex-grow {
  min-height: 0; /* Reset min-height */
}
</style>
