import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { initializeI18n } from './i18n'; // Ensure this import matches the export
import './index.css';

import PageTitle from '@/components/PageTitle.vue';

async function startApp() {
    const i18n = await initializeI18n(); // Asynchronously initialize i18n
    const app = createApp(App);

    app.component('PageTitle', PageTitle);
    app.use(router);
    app.use(i18n);
    app.mount('#app');
}

startApp();
