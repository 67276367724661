<template>
  <BaseLayout>
    <div>
      <page-title message-key="message.title"></page-title>
    </div>

  <img class="p-4" src="@/assets/ecriture.png" alt="Writing Ibn Khaldûn" />
    <div v-html="loadedContent"></div>
  </BaseLayout>
</template>

<script>
import { ref, watch, onMounted } from 'vue';  // Make sure to import watch here
import { useI18n } from 'vue-i18n';
import BaseLayout from '../layouts/BaseLayout.vue';

export default {
  components: {
    BaseLayout,
  },
  setup() {
    const { t, locale } = useI18n();
    const loadedContent = ref('');
    const title = ref(t('message.title'));

    const loadContent = async () => {
      const currentLocale = locale.value || 'en';
      try {
        // Assuming the content is directly importable and the Vite config is set up to handle .html imports
        const content = await import(`../i18n/${currentLocale}.call.html?raw`);
        loadedContent.value = content.default;
      } catch (error) {
        console.error('Failed to load localized content', error);
        loadedContent.value = '';
      }
    };

    watch(locale, loadContent, { immediate: true });

    onMounted(loadContent);

    return { title, loadedContent };
  }
};
</script>
