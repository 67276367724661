import { createRouter, createWebHistory } from 'vue-router';

import Home from '@/views/Home.vue';
import Biographie from '@/views/Biographie.vue';
import AlMuqaddima from '@/views/AlMuqaddima.vue';
import Petition from '@/views/Petition.vue';
import Manuscripts from '@/views/Manuscripts.vue';
import Biblio from '@/views/Biblio.vue';

import ConfirmEmail from '@/components/ConfirmEmail.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/ibnkhaldun',
    name: 'Biographie',
    component: Biographie,
  },
  {
    path: '/texte',
    name: 'AlMuqaddima',
    component: AlMuqaddima,
  },
  {
    path: '/petition',
    name: 'Petition',
    component: Petition,
  },
  {
    path: '/manuscripts',
    name: 'Manuscripts',
    component: Manuscripts,
  },
  {
    path: '/bibliographie',
    name: 'Biblio',
    component: Biblio,
  },
  {
    path: '/confirm/:token',
    name: 'ConfirmEmail',
    component: ConfirmEmail,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
