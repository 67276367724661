<template>
  <nav class="flex justify-between items-center p-4 bg-white shadow-md lg:px-6 relative">
    <a href="/" class="flex-shrink-0">
      <img :src="logo" alt="Ibn Khaldūn - al-Muqaddima" class="h-10 lg:h-12">
    </a>
    <button @click="toggleMenu" class="text-gray-800 lg:hidden">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16m-7 6h7" />
      </svg>
    </button>
    <div :class="menuOpen ? 'flex' : 'hidden'" class="flex-col lg:flex-row absolute inset-x-0 top-full mt-1 bg-white shadow-lg z-50 lg:static lg:z-auto lg:shadow-none lg:w-auto lg:flex lg:space-x-4" :dir="currentDir">
      <a href="/" class="link-base link-lg">{{ $t('message.call') }}</a>
      <a href="/ibnkhaldun" class="link-base link-lg">{{ $t('message.ibnkhaldun') }}</a>
      <a href="/texte" class="link-base link-lg">{{ $t('message.almuqaddima') }}</a>
      <a href="/manuscripts" class="link-base link-lg">{{ $t('message.manuscripts') }}</a>
      <a href="/bibliographie" class="link-base link-lg">{{ $t('message.bibliography') }}</a>
      <button @click="signPetition" class="btn-petition">Sign Petition</button>
      <!-- Language Selection -->
      <div class="flex space-x-2 p-4 lg:p-0 lg:ml-4">
        <a @click.prevent="changeLanguage('en')" :class="{ 'active-locale': locale.value === 'en' }" class="locale-link">EN</a>
        <a @click.prevent="changeLanguage('fr')" :class="{ 'active-locale': locale.value === 'fr' }" class="locale-link">FR</a>
        <a @click.prevent="changeLanguage('ar')" :class="{ 'active-locale': locale.value === 'ar' }" class="locale-link">AR</a>
        <a @click.prevent="changeLanguage('es')" :class="{ 'active-locale': locale.value === 'es' }" class="locale-link">ES</a>
        <a @click.prevent="changeLanguage('de')" :class="{ 'active-locale': locale.value === 'de' }" class="locale-link">DE</a>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import logo from '@/assets/logo.png';

export default {
  setup() {
    const { locale, t } = useI18n();
    const menuOpen = ref(false);

    onMounted(() => {
      console.log("Current locale on mounted:", locale.value);
    });

    function toggleMenu() {
      menuOpen.value = !menuOpen.value;
    }

    function signPetition() {
      window.location.href = '/Petition';
    }

    function changeLanguage(lang) {
  console.log("Changing language to:", lang); // Debug log
  locale.value = lang;
  console.log("Locale after change:", locale.value); // Confirm change
  localStorage.setItem('locale', lang);
  document.documentElement.setAttribute('dir', ['ar', 'he', 'fa'].includes(lang) ? 'rtl' : 'ltr');
  toggleMenu();
}


    const currentDir = computed(() => {
      return ['ar', 'he', 'fa'].includes(locale.value) ? 'rtl' : 'ltr';
    });

    return { logo, menuOpen, toggleMenu, signPetition, changeLanguage, currentDir, t, locale: locale };
  }
};
</script>


<style scoped>
.link-base {
  @apply py-2 px-4 text-gray-700 hover:bg-gray-100 transition duration-200 ease-in-out cursor-pointer;
}

.link-lg {
  @apply lg:border-0 lg:hover:text-gray-900;
}

.btn-petition {
  @apply my-2 lg:my-0 w-full lg:w-auto bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded transition-colors duration-300 cursor-pointer;
}

.locale-link {
  @apply text-sm font-semibold text-gray-600 hover:text-gray-800 transition duration-150 ease-in-out cursor-pointer;
}

.locale-link.active-locale {
  @apply text-red-600 font-bold !important;
}
</style>
