import { createI18n } from 'vue-i18n';
import baseMessages from './messages';

async function loadLocaleMessage(locale) {
  switch (locale) {
    case 'en': return import('./en.call.html?raw');
    case 'fr': return import('./fr.call.html?raw');
    case 'ar': return import('./ar.call.html?raw');
    case 'es': return import('./es.call.html?raw');
    case 'de': return import('./de.call.html?raw');
    default: return { default: '' };  // default empty content if not found
  }
}

async function createMessages() {
  const locale = localStorage.getItem('locale') || 'en';
  const callContent = await loadLocaleMessage(locale);
  
  return {
    ...baseMessages,
    [locale]: { ...baseMessages[locale], call: callContent.default }
  };
}

export async function initializeI18n() {
  const messages = await createMessages();
  return createI18n({
    legacy: false,
    locale: localStorage.getItem('locale') || 'en',
    fallbackLocale: 'en',
    messages
  });
}
