<template>
    <h1 class="font-bold leading-none text-4xl sm:text-6xl md:text-7xl lg:text-8xl">
      {{ localizedTitle }}
    </h1>
  </template>
    
  <script>
  export default {
    name: 'PageTitle',
    props: {
      messageKey: {
        type: String,
        required: true
      }
    },
    computed: {
      localizedTitle() {
        return this.$t(this.messageKey);
      }
    }
  }
  </script>
  
  <style>
  /* Add component-specific styles here if needed */
  </style>
  