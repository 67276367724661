<template>
  <BaseLayout>
    <!-- Main content rendered via router-view, wrapped by BaseLayout for consistent styling -->
    <router-view></router-view>
  </BaseLayout>
</template>

<script>
import BaseLayout from './layouts/BaseLayout.vue';

export default {
  name: 'App',
  components: {
    BaseLayout
  }
};
</script>

<style>
/* Global styles can still be defined here if needed */
body {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* You might want to move specific styles to relevant components or global stylesheet */
</style>
