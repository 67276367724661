<template>
  <!DOCTYPE html>
  <div class="bg-white min-h-screen flex flex-col p-8">
    <header class="fixed inset-x-0 top-0 z-50"> <!-- Changed to fixed to ensure it's always on top -->
      <Menu /> <!-- Ensure Menu component is used here -->
    </header>
    <main class="flex-grow pt-24"> <!-- Increased padding to account for a potentially bigger header -->
      <slot></slot>
    </main>
    <footer>
      <!-- Footer content -->
    </footer>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue';

export default {
  components: {
    Menu,
  },
  name: 'BaseLayout'
};
</script>