<template>
  <BaseLayout>
    <div>
      <page-title message-key="message.listmanuscripts"></page-title>
    </div>
    <ul>
      <li v-for="copie in copies" :key="copie.id">
        <strong>{{ copie.cote }}:</strong>
        {{ copie.library.nom_en }} - {{ copie.library.ville.pays.nom_en }}
      </li>
    </ul>
  </BaseLayout>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import BaseLayout from '../layouts/BaseLayout.vue';

const copies = ref([]);

onMounted(async () => {
  try {
    const response = await fetch('https://back.al-muqaddima.net/api/copies/');
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    copies.value = data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
});
</script>

<style scoped>
/* Your styles here */
</style>
